import React from "react"
import { FaLinkedin } from "react-icons/fa"
import ShareIcon from "./ShareIcon"
import styled from "styled-components"

const ShareArea = styled.div`
  svg {
    width: 30px;
    height: 30px;
    margin-right: var(--spacing);
  }

  a {
    &:after {
      display: none;
    }
  }
`

const ShareCont = ({ linkedin, linkto, titlearticle }) => {
  return (
    <ShareArea>
      <h4>Share this blog via {'\u00A0'}{'\u00A0'}
      {linkedin && (
        <ShareIcon
          shareUrl={`https://www.linkedin.com/shareArticle?mini=true&url=${linkto}&title=${titlearticle}]`}
        >
          <FaLinkedin />
        </ShareIcon>
      )}
      </h4>
    </ShareArea>
  )
}

export default ShareCont
